/**
 * 
 * This is an object of namespaced variables accessible on the window object so you 
 * can access it from any other javascript
 * 
 * // defaults.js
 * Ornament.eventNamespace = "terrischeer";
 * 
 * // my-component.js
 * $(myElement).trigger(Ornament.trackingNamespace + ":my-event");
 * 
 */

export default Ornament = window.Ornament = {

  externalLinkExtensions: [],
  internalLinkSelectors: []

};
