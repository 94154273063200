/**
 *
 * This is a patch for jQuery UI autocomplete to add a highlight around the 
 * result matching the input query.
 *
 * eg:
 * Input value = "test"
 * result = "<span class='autocomplete-highlighted'>test</span>ing a result"
 *
 * Sourced & expanded from:
 * http://stackoverflow.com/questions/2435964/jqueryui-how-can-i-custom-format-the-autocomplete-plug-in-results
 *
 */

import $ from 'jquery';

$(function() {
  var oldFn = $.ui.autocomplete.prototype._renderItem;
  $.ui.autocomplete.prototype._renderItem = function(ul, item) {
    var old_term = this.term;
    var term = this.term.toUpperCase();
    var highlighted = new RegExp(term);
    var matched_at = item.label.toUpperCase().search(highlighted);
    var t = item.label;

    if (matched_at >= 0) {

      var end_match = matched_at + term.length
      t = item.label.substring(0, matched_at) +
          "<span class='autocomplete-highlighted'>" +
          item.label.substring(matched_at, end_match) +
          "</span>" +
          item.label.substring(end_match, item.label.length);
    }

    return $("<li></li>").data("item.autocomplete", item).append("<a>"+t+"</a>").appendTo(ul);
  };
});
