// Multicolumn autocomplete
// http://jsfiddle.net/alforno/g4stL/

import $ from 'jquery';

$.widget('custom.mcautocomplete', $.ui.autocomplete, {
  _renderItem: function(ul, item) {
    var t = '',
        result = '',
        old_term = this.term,
        term = this.term.toUpperCase(),
        highlighted = new RegExp(term);

    if(typeof(item.name) == "undefined") {
      return $('<span class="nomatches"></span>').data('ui-autocomplete-item', item).append('No matches found...').appendTo(ul);
    }

    var matched_name_at = item.name.toUpperCase().search(highlighted),
        matched_suburb_at = item.suburb.toUpperCase().search(highlighted),
        matched_state_at = item.state.toUpperCase().search(highlighted);

    // highlight matches
    if (matched_name_at >= 0) {
      var end_match = matched_name_at + term.length;
      var old_name = item.name;
      item.name = old_name.substring(0, matched_name_at) +
          "<span class='autocomplete-highlighted'>" +
          old_name.substring(matched_name_at, end_match) +
          "</span>" +
          old_name.substring(end_match, old_name.length);
    }
    if (matched_suburb_at >= 0) {
      var end_match = matched_suburb_at + term.length;
      var old_suburb = item.suburb;
      item.suburb = old_suburb.substring(0, matched_suburb_at) +
          "<span class='autocomplete-highlighted'>" +
          old_suburb.substring(matched_suburb_at, end_match) +
          "</span>" +
          old_suburb.substring(end_match, old_suburb.length);
    }
    if (matched_state_at >= 0) {
      var end_match = matched_state_at + term.length;
      var old_state = item.state;
      item.state = old_state.substring(0, matched_state_at) +
          "<span class='autocomplete-highlighted'>" +
          old_state.substring(matched_state_at, end_match) +
          "</span>" +
          old_state.substring(end_match, old_state.length);
    }

    $.each(this.options.columns, function(index, column) {
      t += '<span class="mcacCell" style="flex-basis: ' + column.width + '">' + item[column.valueField ? column.valueField : index] + '</span>';
    });

    result = $('<li></li>').data('ui-autocomplete-item', item).append('<a class="mcacRow">' + t + '</a>').appendTo(ul);
    return result;
  }
});
