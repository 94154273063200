/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable";
import "regenerator-runtime/runtime";

// =========================================================================
// Reset
// =========================================================================

import "reset-css";

// =========================================================================
// Images
// =========================================================================

// Copies all static images under ../images to the output folder. Reference
// them with the image_pack_tag helper in views
// (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// =========================================================================
// Libraries
// =========================================================================

import jQuery from 'jquery';

global.$ = jQuery;
global.jQuery = jQuery;

require('jquery-ujs'); // using require instead of import so jQuery is available

import 'webpack-jquery-ui/autocomplete';
import 'webpack-jquery-ui/css';
import 'webpack-jquery-ui/datepicker';

import 'magnific-popup';
import "magnific-popup/src/css/main.scss";

import 'jquery-placeholder';
import 'jquery-price-format';

// =========================================================================
// Stylesheets
// =========================================================================

import '../src/application.scss';

// =========================================================================
// Ornament defaults
// =========================================================================

import '../src/ornament/defaults.js'
import '../src/ornament/accessibility.js'
import '../src/ornament/external_links.js'
import '../src/ornament/refresh.js'

// =========================================================================
// Page-specific scripts
// =========================================================================

import '../src/application.js'
import '../src/policies.js'
import '../src/quotes.js'
import '../src/policy_holders.js'
import Payment from '../src/payment.js'

global.Payment = Payment;

// =========================================================================
// Global site components
// =========================================================================

import '../src/components/mcautocomplete.js'
import '../src/components/tooltip.js'
import '../src/components/autocomplete_patch.js'
import '../src/components/lightbox.js'
import '../src/components/form_helpers.js'
import '../src/components/show.js'
import '../src/components/popout.js'
import '../src/components/product_type.js'
import '../src/components/jeopardy.js'
