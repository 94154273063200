// These are scripts specifically for the quotes page

import $ from 'jquery';
import $scrollto from 'jquery.scrollto';
import spinner from "../images/spinner.gif";

$(function () {

  // =========================================================================
  // Helpers
  // =========================================================================

  /**
   * addPolicyNames helper
   *
   * Format a cover name to always add the term "Policy" if not already
   * present
   */
  function addPolicyNames(cover_name) {
    if (cover_name.indexOf("Policy") > -1) {
      return cover_name;
    } else {
      return cover_name + " Policy";
    }
  }

  // Helper to scroll to the #quote-premium element for mobile
  function mobileScroll() {
    $scrollto($("#quote-premium"), 800, {queue: true});
  }

  // Reset prices UI to show spinners
  function refreshingPrices() {
    $('#cover_1_premium').html('<img src=' + spinner + ' />');
    $('#cover_2_premium').html('<img src=' + spinner + ' />');
    $('#total_premium').html('<img src=' + spinner + ' />');
    $('#call_centre_price').html('<img src=' + spinner + ' />');
  }

  // =========================================================================
  // Update prices in the UI with new application data
  // =========================================================================

  /**
   * Take an application object and update the various elements on the page
   * with the new appliction data
   */
  function updatePrices(application) {
    if (application.cover_1_name) {
      application.cover_1_name = addPolicyNames(application.cover_1_name);
      $('#cover_1_name').html(application.cover_1_name);
      $('#cover_1_premium').html(application.cover_1_premium);
      $('#cover_1_break_down_base_premium').html(application.cover_1_base_premium);
      $('#cover_1_break_down_esl').html(application.cover_1_esl);
      $('#cover_1_break_down_gst').html(application.cover_1_gst);
      $('#cover_1_break_down_stamp_duty').html(application.cover_1_stamp_duty);
      $('#cover_1_break_down_premium').html(application.cover_1_premium);
    }

    if (application.cover_2_name) {
      application.cover_2_name = addPolicyNames(application.cover_2_name);
      $('#cover_2_name').html(application.cover_2_name);
      $('#cover_2_premium').html(application.cover_2_premium);
      $('#cover_2_break_down_base_premium').html(application.cover_2_base_premium);
      $('#cover_2_break_down_esl').html(application.cover_2_esl);
      $('#cover_2_break_down_gst').html(application.cover_2_gst);
      $('#cover_2_break_down_stamp_duty').html(application.cover_2_stamp_duty);
      $('#cover_2_break_down_premium').html(application.cover_2_premium);
    }

    $('#total_premium').html(application.total_premium);
    $('#call_centre_price').html(application.call_centre_price);
  }

  // =========================================================================
  // Changing quote price
  // =========================================================================

  function quoteChange(event) {

    // Get the sum insured value
    let contents_sum_insured = $("input[type='radio'][name='quote[contents_sum_insured]']:checked").val();
    if (typeof contents_sum_insured == 'undefined') {
      contents_sum_insured = $('#quote_contents_sum_insured').val();
    }

    // Get various settings to send to the quote endpoint
    const building_sum_insured = $('#quote_building_sum_insured').val();
    const rent_excess = $("input[type='radio'][name='quote[rent_excess]']:checked").val();
    const building_excess = $("input[type='radio'][name='quote[building_excess]']:checked").val();
    const dataString = 'quote[contents_sum_insured]=' + contents_sum_insured +
      '&quote[building_sum_insured]=' + building_sum_insured +
      '&quote[rent_excess]=' + rent_excess +
      '&quote[building_excess]=' + building_excess;

    // Send data to the endpoint
    $.ajax({
      type: "POST",
      url: "/your_quote.json",
      data: dataString,
      headers: { Accept: "application/json; charset=utf-8" },

      // UI updates to occur before the ajax request is sent
      beforeSend: function () {
        // Scroll the window to the quotes element on mobile
        if ($(window).width() <= 900) {
          mobileScroll();
        }
        // Update the price UI to show spinners
        refreshingPrices();
      }
    }).done(function (data) {
      /**
       * Once the application is returned from the backend, update the UI with the new
       * data
       */
      updatePrices(data);

      // Start tracking how much is insured and update based on the data returned
      let totalInsured = 0.0;

      /**
       * Update the contentsCover analytics tracking data
       * add value to totalInsured
       */
      if (dataLayer.dataModel.contentsCover) {
        if (contents_sum_insured) {
          const contents_sum_insured_number = parseFloatStripQuotes(contents_sum_insured);
          dataLayer.dataModel.contentsCover.sumInsuredContents = contents_sum_insured_number;
          totalInsured += contents_sum_insured_number;
        }
        if (rent_excess) {
          dataLayer.dataModel.contentsCover.excess = parseFloatStripQuotes(rent_excess);
        }
      }

      // Update the buildCover analytics tracking data add value to totalInsured
      if (dataLayer.dataModel.buildingCover) {
        if (building_sum_insured) {
          const building_sum_insured_number = parseFloatStripQuotes(building_sum_insured);
          dataLayer.dataModel.buildingCover.sumInsuredBuilding = building_sum_insured_number;
          totalInsured += building_sum_insured_number;
        }
        if (building_excess) {
          dataLayer.dataModel.buildingCover.excess = parseFloatStripQuotes(building_excess);
        }
      }

      // Update the quote and insured amount in the analytics tracking data
      dataLayer.dataModel.quoteAmount = parseFloatStripQuotes(data.total_premium);
      dataLayer.dataModel.insuredAmount = totalInsured;
    });
    return false;
  }

  // Call quoteChange whenever the quote input is changed
  $('#new_quote input').off("change", quoteChange).on("change", quoteChange);

  // IE/mobile doesn't fire change events for text inputs reliably, use blur instead
  $("#new_quote input[type=text], #new_quote input[type=currency]").on("blur", function () {
    $(this).change();
  });

  // =========================================================================
  // Save quote form
  // =========================================================================

  /**
   * On mobile, the save form is hidden and toggled via a big "Email my Quote"
   * heading
   * Here we bind the heading to toggle the visibility of the form
   */
  $(".quote--save-form--heading").on("click", function (e) {
    e.preventDefault();

    if ($(window).width() <= 600) {
      const $this = $(this);
      $this.parent(".island").toggleClass("quote--save-form-visible");
      $this.next(".quote--save-form--content").slideToggle();
    } else {
      return false;
    }
  });

  // Analytics event for email quote button
  $("[data-ensighten-email-quote]").on("click", function () {
    webAnalytics.publish("event", "QUOTE_EMAILED");
  });

  // Get elements for quote saving form
  const $saveQuote = $("#quote--email-form");
  const $saveForm = $("#new_save_quote");
  const $saveSuccess = $("#quote--email-success");
  const $saveReloadLink = $("#quote--email--reload");
  const $saveError = $("#quote--email--error");
  const $saveField = $("#save_quote_email_address");

  // Hide the success/error messages on page load
  $saveError.hide();
  $saveSuccess.hide();

  /**
   * When the save form is submitted, we send the data to the save endpoint and
   * show either a success or error message based on the response from the backend
   */
  $saveForm.on("submit", function (e) {
    e.preventDefault();

    // Validation error messages
    const emailErrorValid = "Please enter a valid email address";
    const emailErrorBlank = "Please enter your email address";
    const emailErrorGeneric = "Error sending email";
    /**
     * emailRegex - RFC2822
     * http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
     */
    var emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    /**
     * Front-end validations
     * If a validation fails, update the error message, hide the success message
     *
     * First check if the email field is empty
     */
    if ($saveField.val() === "") {
      $saveSuccess.hide();
      $saveError.text(emailErrorBlank);
      $saveError.show();

      // Next, check if valid email
    } else if (!emailRegex.test($saveField.val())) {
      $saveSuccess.hide();
      $saveError.text(emailErrorValid);
      $saveError.show();

      // If above validations pass, send data to backend
    } else {
      // Hide any existing success message
      $saveSuccess.hide();
      // Update any errors to show generic error message
      $saveError.text(emailErrorGeneric);

      $.post({
        url: "/save_quotes",
        data: { "save_quote[email_address]": $('#save_quote_email_address').val() },
        headers: { Accept: "application/json; charset=utf-8" }
      })
        .done(function (data) {
          if (data === 0) {
            // show success message, hide the save quote form
            $saveQuote.hide();
            $saveSuccess.show();
            // update datalayer action
            window.dataLayer.action = "QUOTE_EMAILED";
          } else {
            // show error message
            $saveError.show();
          }
        });
    }
  });

  /**
   * Quotes can only be saved once.
   * If the user wishes to reload the form, they can click a reload button
   * to hide errors/success messaging, reset the form and show the form again
   */
  $saveReloadLink.on("click", function (e) {
    e.preventDefault();
    $saveError.hide();
    $saveSuccess.hide();
    $saveField.val("");
    $saveQuote.show();
  });

});

// Helper for stripping spaces from quote floats
function parseFloatStripQuotes(string) {
  return parseFloat(string.replace(/[,|$]/g, ""))
}
