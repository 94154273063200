/**
 *
 * Popout
 *
 * Popouts are boxes of content attached to form fields
 * Currently only used on the policy details page attached to option 3 for the
 * 10% discount
 *
 * It's implemented as a jQuery plugin so you can use it as:
 * $(myElement).popOut({ html: "<p>This is some HTML in the popout</p>" });
 *
 * There are two popouts rendered, a desktop and mobile version to help with
 * styling
 *
 */

import $ from 'jquery';

$.fn.popOut = function (options) {
  var $anchor = this;
  var $popOut = $("<div class='popout'>" + options.html + "</div>");

  // Add popout
  $anchor.addClass("popout--anchor").parent().append($popOut);

  // Add mobile popout
  var $mobilePopOut = $popOut.clone().addClass("popout-small");
  $anchor.parent().after($mobilePopOut);
};

// Implementing our popouts
$(function () {
  $("#policy_cover_option_3").popOut({
    "html": "<strong>10% DISCOUNT</strong> off your Residential Building Insurance policy when you combine coverage"
  });
});
