/**
 * 
 * There are several public helpers to check what TerriScheer product the
 * user is currently eligible for
 * 
 * eg. setting "x", "y" and "z" to true makes them eligible for "xyz" product
 * you can use is_xyz() to check if those fields are true
 * you can use show_xyz() to show any appropriate fields for the xyz product
 * you can use hide_xyz() to hide any unnecessary fields for the xyz product
 * 
 * available product types to check against:
 * • sss
 * • lpp
 * • lsm
 * • rbi
 * • combined
 * 
 * These "products" are TerriScheer terminology products and match their own
 * internal glossary
 * 
 */

import $ from 'jquery';

/**
 * These are public helpers to check what sort of "product" a user is currently
 * in the process of getting
 */
var is_sss = function(){
  return checkProductType("sss");
}
var is_lpp = function(){
  return checkProductType("lpp");
}
var is_lsm = function(){
  return checkProductType("lsm");
}
var is_rbi = function(){
  return checkProductType("rbi");
}
var is_combined = function(){
  return checkProductType("combined");
}

/**
 *
 * These are public helpers to show/hide elements based on which product
 * the user is in the process of getting
 * 
 * If the user is currently getting an "sss" product and you want to show any fields
 * that are relevant to the "sss" product and hide any fields that aren't relevant
 * 
 * To create an element that will show when the user switches to the SSS product:
 * <div data-show-on="is_sss()">
 * 
 * To create an element that will hide when the user switches to the SSS product:
 * <div data-show-on="!is_sss()">
 *
 */
var show_sss = function(){
  $("[data-show-on='is_sss()']").each(function(){
    $(this).slideDown(200);
  });
  $("[data-show-on='!is_sss()']").each(function(){
    $(this).slideUp(200);
  });
}
var hide_sss = function(){
  $("[data-show-on='is_sss()']").each(function(){
    $(this).slideUp(200);
  });
  $("[data-show-on='!is_sss()']").each(function(){
    $(this).slideDown(200);
  });
}
var show_lpp = function(){
  $("[data-show-on='is_lpp()']").each(function(){
    $(this).slideDown(200);
  });
  $("[data-show-on='!is_lpp()']").each(function(){
    $(this).slideUp(200);
  });
}
var hide_lpp = function(){
  $("[data-show-on='is_lpp()']").each(function(){
    $(this).slideUp(200);
  });
  $("[data-show-on='!is_lpp()']").each(function(){
    $(this).slideDown(200);
  });
}
var show_lsm = function(){
  $("[data-show-on='is_lsm()']").each(function(){
    $(this).slideDown(200);
  });
  $("[data-show-on='!is_lsm()']").each(function(){
    $(this).slideUp(200);
  });
}
var hide_lsm = function(){
  $("[data-show-on='is_lsm()']").each(function(){
    $(this).slideUp(200);
  });
  $("[data-show-on='!is_lsm()']").each(function(){
    $(this).slideDown(200);
  });
}
var show_rbi = function(){
  $("[data-show-on='is_rbi()']").each(function(){
    $(this).slideDown(200);
  });
  $("[data-show-on='!is_rbi()']").each(function(){
    $(this).slideUp(200);
  });
}
var hide_rbi = function(){
  $("[data-show-on='is_rbi()']").each(function(){
    $(this).slideUp(200);
  });
  $("[data-show-on='!is_rbi()']").each(function(){
    $(this).slideDown(200);
  });
}
var show_combined = function(){
  $("[data-show-on='is_combined()']").each(function(){
    $(this).slideDown(200);
  });
  $("[data-show-on='!is_combined()']").each(function(){
    $(this).slideUp(200);
  });
}
var hide_combined = function(){
  $("[data-show-on='is_combined()']").each(function(){
    $(this).slideUp(200);
  });
  $("[data-show-on='!is_combined()']").each(function(){
    $(this).slideDown(200);
  });
}

/**
 * checkProductType checks to see if the user is currently getting that product
 * based on the selections they have made so far
 * 
 * checkProductType("sss") -> true/false
 */
var checkProductType = function(type){

  /**
   * These are the fields in the form that will be checked to determine which
   * "product" the user is getting
   */
  var $policyLLI = $("#policy_cover_option_1");
  var $policyBI = $("#policy_cover_option_2");
  var $policyCombined = $("#policy_cover_option_3");
  var $managedMyself = $("#policy_property_managed_i_manage_the_property_myself");
  var $managedOther = $("#policy_property_managed_i_have_a_property_or_on-site_manager");
  var $rentalTrue = $("#policy_short_term_rental_true");
  var $rentalFalse = $("#policy_short_term_rental_false");

  /**
   * Go over each of the product types and check each field above to see if they match
   * the requested product
   * 
   * If the requested product DOES match, return true and show any fields that are
   * hidden and should be shown for the matching product
   */
  switch(type) {
    case "sss":
      if( $policyCombined.is(":checked") && $managedOther.is(":checked") && $rentalTrue.is(":checked") ) {
        show_sss();
        return true;
      } else if ( $policyLLI.is(":checked") && $managedOther.is(":checked") && $rentalTrue.is(":checked") ) {
        show_sss();
        return true;
      } else {
        hide_sss();
        return false;
      }      
      break;
    case "lpp":
      if( $policyCombined.is(":checked") && $managedOther.is(":checked") && $rentalFalse.is(":checked") ) {
        show_lpp();
        return true;
      } else if( $policyLLI.is(":checked") && $managedOther.is(":checked") && $rentalFalse.is(":checked") ) {
        show_lpp();
        return true;
      } else {
        hide_lpp();
        return false;
      }
      break;
    case "lsm":
      if( $policyCombined.is(":checked") && $managedMyself.is(":checked") ) {
        show_lsm();
        return true;
      } else if( $policyLLI.is(":checked") && $managedMyself.is(":checked") ) {
        show_lsm();
        return true;
      } else {
        hide_lsm();
        return false;
      }
      break;
    case "rbi":
      if( $policyBI.is(":checked") || $policyCombined.is(":checked") ) {
        show_rbi();
        return true;
      } else {
        hide_rbi();
        return false;
      }
      break;
    case "combined":
      if( $policyCombined.is(":checked") ) {
        show_combined();
        return true;
      } else {
        hide_combined();
        return false;
      }
      break;
    default:
      return false;
      break;
  }
}

$(function(){

  // Debugger helper to log out what product the user is currently matching for
  var debug = false;
  var debugOutput = function(){
    console.log("------------");
    console.log(" PRODUCT TYPE DEBUG ");
    console.log("------------");
    console.log("is_sss: " + is_sss());
    console.log("is_lpp: " + is_lpp());
    console.log("is_lsm: " + is_lsm());
    console.log("is_rbi: " + is_rbi());
    console.log("is_combined: " + is_combined());
    console.log("------------");
  }

  // Run all the checks and show/hide the appropriate fields
  var runChecks = function(){
    is_sss();
    is_lpp();
    is_lsm();
    is_rbi();
    is_combined();
    if(debug) {
      debugOutput();
    }
  }
  
  // Run checks on page load
  runChecks();

  // Run all checks when changing any input field
  $("input").on("change", function(){
    runChecks();
  });

});

// Exports
global.is_combined = is_combined;
global.is_lpp = is_lpp;
global.is_lsm = is_lsm;
global.is_rbi = is_rbi;
global.is_sss = is_sss;
