// These are scripts specifically for the policy holders page

import $ from 'jquery';

$(function () {

  // =========================================================================
  // Mailing location
  // =========================================================================

  // Mailing location autocomplete
  const mailing_location = $("#policy_holder_mailing_address_location");
  mailing_location.autocomplete({
    source: "/locations/?type=" + $("input[type=radio][name='policy_holder[mailing_address_type]']:checked").val(),
    minLength: 2,
    change: function (event, ui) {
      //Get plugin data for 'this'
      const data = $.data(this);

      /**
       * If there is no selected item and the user has typed something in to the
       * field, we want to empty the field so the irrelevant data doesn't get
       * saved in the form
       * This forces the user to make a selection from the autocomplete results
       */
      if (data.uiAutocomplete.selectedItem === undefined) {
        $(this).val("");
      }
    }
  });

  // Get new autocomplete results when changing address type
  $("input[type=radio][name='policy_holder[mailing_address_type]']").change(function () {
    mailing_location.autocomplete({
      source: "/locations/?type=" + $("input[type=radio][name='policy_holder[mailing_address_type]']:checked").val(),
      minLength: 2
    });
  });

  // =========================================================================
  // Country location check
  // Deprecated
  // =========================================================================

  /*
  var countryLocationCheck = function() {
    // var country_field  = $('#policy_holder_mailing_address_country');
    // var location_field = $('#policy_holder_mailing_address_location');
    // var state_field    = $('#policy_holder_mailing_address_state');
    // var suburb_field   = $('#policy_holder_mailing_address_suburb');
    // var postcode_field = $('#policy_holder_mailing_address_postcode');

    // if(country_field.val() == 'AU') {
    //   location_field.slideDown(200);
    //   state_field.slideUp(200);
    //   suburb_field.slideUp(200);
    //   postcode_field.slideUp(200);
    // } else {
    //   location_field.slideUp(200);
    //   state_field.slideDown(200);
    //   suburb_field.slideDown(200);
    //   postcode_field.slideDown(200);
    // }
  }

  countryLocationCheck()
  $('#policy_holder_mailing_address_country').on("change", function() {
    countryLocationCheck()
  });
  */

  // =========================================================================
  // Policy holder / contact variation
  // =========================================================================

  /** Update the policy holder types throughout the page */
  function updatePolicyHolder (string) {
    $("[data-policy-holder-landlord-type]").text(string);
  }

  /** Update the landlord type throughout the page */
  function updateLandlordType(string) {
    $("[data-landlord-type]").text(string);
  }

  /**
   * When the user changes the company setting, the policy
   * holder references throughout the page need to change
   */
  $("input[type=radio][name='policy_holder[landlord_type]']").on("change", function () {
    if ($(this).val() === "People") {
      updatePolicyHolder("Policy Holder");
    } else {
      updatePolicyHolder("Contact");
    }
  });

  /**
   * When the user changes the policy holder type setting, the
   * landlord type references throughout the page need to change
   */
  $("[id^=policy_holder_landlord_type]").on("change", function () {
    if ($(this).prop("checked")) {
      updateLandlordType($(this).prop("value").toLowerCase());
    }
  });

  // set policy holder type by default
  updatePolicyHolder("Policy Holder");

  // =========================================================================
  // Policy holder repeater
  // Users can add up to four new policy holders
  // =========================================================================

  // Track how many additional policy holders there are
  let additionalHoldersCount = 0;

  // Cache elements
  const $additionalHolders = $(".policy_holder_extra");
  const $policyholderAddButton = $(".policy_holder-add");
  const $policyholderRemoveButton = $(".policy_holder-remove");

  // Hide additional holders by default
  $additionalHolders.hide();

  /**
   * Show the existing policy holders contextually
   * Only policy holders with content in them should be visible
   * Only the last policy holder should show the add button
   * Only the last policy holder should show the remove button
   */
  function showHoldersContextually() {

    /**
     * Loop over each of the additional holder elements and figure out
     * many we need to show
     */
    $additionalHolders.each(function () {
      const $holder = $(this);
      const $holderFields = $holder.find("input");
      const holderNumber = $holder.attr("id").split("policy_holder_")[1];
      let showThisHolder = false;

      // Loop through each field in this holder to see if it should be visible
      $holderFields.each(function () {

        // don't bother running this logic again if we already intend to show this holder
        if (showThisHolder) {
          return false;
        }

        const $thisField = $(this);

        // check for inputs with content
        if ($thisField.val() !== "") {

          /**
           * check for ticked radio buttons - radio buttons are marked as true at
           * this point as their values are set by their labels
           */
          if ($thisField.is("input[type=radio]")) {
            showThisHolder = $thisField.prop("checked");
          } else {
            showThisHolder = true;
          }
        }

        // Show the holder if required
        if (showThisHolder) {
          // $holder.show();
          // Move the add button to this holder
          // $policyholderAddButton.appendTo($holder);
          // update holders count
          additionalHoldersCount = parseInt(holderNumber) - 1;
        }

      });

    });

    /**
     * Loop over each policy holder and show them based on the additionalHoldersCount
     * variable
     */
    $additionalHolders.each(function () {
      const $holder = $(this);
      const holderId = $holder.attr("id");
      if (parseInt(holderId.split("policy_holder_")[1]) < (additionalHoldersCount + 1)) {
        // show all previous and current holders
        $holder.show();
        // hide all remove buttons
        $holder.find(".policy_holder-remove").hide();
      }
    });

    /**
     * The add button should appear after the last holder
     * The remove button should only appear on the last holder
     */
    const $latestHolder = $("#policy_holder_" + (additionalHoldersCount + 1));
    $policyholderAddButton.appendTo($latestHolder);
    $latestHolder.find(".policy_holder-remove").show();
    if (additionalHoldersCount === 4) {
      $policyholderAddButton.hide();
    }
    $latestHolder.show();
  };

  // If there are any errors on the page, show policy holders
  if ($(".error").length > 0 || $(".field_with_errors").length > 0 || $(".existing_policy_holder").length > 0) {
    showHoldersContextually();
  }

  // Add a new policy holder
  $policyholderAddButton.on("click", function (e) {
    e.preventDefault();

    // increment the count by one
    additionalHoldersCount++;

    // hide this remove button
    const $thisHolder = $(this).closest(".policy_holder_extra");
    $thisHolder.find(".policy_holder-remove").hide();

    // show the next holder
    const $newHolder = $("#policy_holder_" + (additionalHoldersCount + 1));
    $newHolder.show();

    // move the add button to the next holder
    $policyholderAddButton.appendTo($newHolder);

    // hide the add button if already showing five
    if (additionalHoldersCount === 4) {
      $policyholderAddButton.hide();
    }
  });

  // Removing a Policy Holder
  $policyholderRemoveButton.on("click", function (e) {
    const $thisButton = $(this);

    // reduce the count by one
    additionalHoldersCount--;

    // hide this policy holder
    const $holderContainer = $thisButton.closest(".policy_holder_extra");
    const $previousHolder = $holderContainer.prev("div");
    $holderContainer.hide();

    // move the add button back
    if (additionalHoldersCount === 0) {
      $policyholderAddButton.appendTo($previousHolder.find(".forms--nested"));
    } else {
      $policyholderAddButton.appendTo($previousHolder).show();
    }

    // show the previous remove button again
    $previousHolder.find(".policy_holder-remove").show();

    // empty out the fields in the removed holder
    $holderContainer.find("input[type=text]").val("");
    $holderContainer.find("input[type=email]").val("");
    $holderContainer.find("input[type=date]").val("");
    $holderContainer.find("input[type=number]").val("");
    $holderContainer.find("input[type=tel]").val("");
    $holderContainer.find("input[type=radio]").prop("checked", false).parent("label").removeClass("radio-active");
  });

});
