/*jslint browser: true, indent: 2, todo: true, unparam: true */
/*global jQuery,Ornament */

/**
 *
 * Integration helpers for magnific popup
 * https://dimsemenov.com/plugins/magnific-popup/
 *
 * Custom close buttons:
 * <button class="lightbox-close">Close</button>
 *
 * Helper classes to bind popups:
 * <a href="#popup-content-element" class="lightbox-inline">Open inline popup</a>
 *
 */

import $ from 'jquery';
import 'magnific-popup';

/**
 * Any elements with .lightbox-close class will close any
 * open lightboxes
 */
$(".lightbox-close").on("click", function (e) {
  e.preventDefault();
  $.magnificPopup.close();
});

$(document).on("ornament:refresh", function () {

  // Helper for binding an inline magnific popup link
  $('.lightbox-inline').each(function () {
    var $thisLightbox = $(this);
    var lightboxType = "inline";
    $thisLightbox.magnificPopup({
      type: lightboxType,
      midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });
  });
});
