function handleResponse(response) {
  if ("ok" === response.status) {
    $("#new_payment_form").trigger("submit.rails");
  } else if ("fields_in_error" === response.status) {
    if (response.errors.cardNumber) {
      errorMessageFor("#payment_form_card_number", response.errors.cardNumber);
    }

    if (response.errors.expiryMonth) {
      errorMessageFor("#payment_form_expiry_month", response.errors.expiryMonth);
    } else if (response.errors.expiryYear) {
      errorMessageFor("#payment_form_expiry_year", response.errors.expiryYear);
    }

    if (response.errors.securityCode) {
      errorMessageFor("#payment_form_security_code", response.errors.securityCode);
    }
  }
}

function showError(message) {
  const element = document.querySelector("#payment_form_errors");
  element.innerHTML = message;
  element.classList.remove("hidden");
}

function resetError() {
  const element = document.querySelector("#payment_form_errors");
  element.innerHTML = "";
  element.classList.add("hidden");
}

function errorMessageFor(id, message) {
  const el = document.querySelector(id);
  el.parentElement.classList.add("field_with_errors");
  const label = el.parentElement.querySelector("label");
  if (label.querySelector("span")) {
    label.removeChild(label.firstElementChild)
  }
  label.insertAdjacentHTML("beforeend", "<span> is " + message + "</span>");
}

function configure() {
  $("#buy_now_button").on("click", function (e) {
    e.preventDefault();
    if (e.currentTarget.disabled) return false;
    PaymentSession.updateSessionFromForm('card');
  });

  PaymentSession.configure({
    session: $("#payment_form_session_id").val(),
    fields: {
      card: {
        number: "#payment_form_card_number",
        nameOnCard: "#payment_form_cardholder_name",
        expiryMonth: "#payment_form_expiry_month",
        expiryYear: "#payment_form_expiry_year",
        securityCode: "#payment_form_security_code"
      }
    },
    frameEmbeddingMitigation: ["javascript"], // TODO replace with csp
    interaction: {
      displayControl: {
        formatCard: "EMBOSSED",
        invalidFieldCharacters: "REJECT"
      }
    },
    callbacks: {
      initialized: function (response) {
        switch (response.status) {
          case "ok":
            break; // no-op, form is ready
          case "request_timeout":
            window.location = "/system-unavailable-payment-timeout"
            console.log(response.message);
            break;
          default:
            window.location = "/system-unavailable-payment-error"
            console.log(response.message);
            break;
        }
      },
      formSessionUpdate: function (response) {
        resetError();
        if (response.status) {
          handleResponse(response);
        } else {
          showError("Payment session unavailable.");
        }
      }
    }
  });
  PaymentSession.setFocusStyle(["card.number", "card.nameOnCard", "card.expiryMonth", "card.expiryYear", "card.securityCode"], {
    border: "1px solid #16929c"
  })
}

export default {configure};
