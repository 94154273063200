import $ from 'jquery';

// global error handler for ajax redirects
$(function () {
  $(document).ajaxError(function (event, response) {
    // If the request fails, the endpoint should return a URL to send the user to
    if (response.responseJSON !== undefined && response.responseJSON.location !== undefined) {
      window.location = response.responseJSON.location;
    }
  })
});

// Anti-clickjacking protection, prevents embedding.
// TODO(sfn) replace with CSP
if (self === top) {
  const antiClickjack = document.getElementById("antiClickjack");
  antiClickjack.parentNode.removeChild(antiClickjack);
} else {
  top.location = self.location;
}
