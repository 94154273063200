/*jslint browser: true, indent: 2, todo: true, unparam: true */
/*global jQuery,Ornament */

import $ from 'jquery';

/**
 *
 * Tooltips
 *
 * Create a tooltip anchor inside an element that shows a tooltip when clicked.
 *
 * <p data-tooltip-anchor="help" data-tooltip-message="This is your tooltip message">
 *   This is some content that appears alongside your tooltip
 * </p>
 *
 * This will result in:
 *
 * <p>
 *   This is some content that appears alongside your tooltip
 *   <span class="tooltip--anchor">help</span>
 * </p>
 *
 * If the element you're adding the data attributes to contains a .control-label element,
 * the anchor will be added to the end of the control-label as a concept of providing
 * optional hidden help messaging for a form field.
 *
 * <div data-tooltip-anchor="help" data-tooltip-message="This is your tooltip message">
 *   <%= f.input :my_input %>
 * </div>
 *
 * This will result in:
 *
 * <div class="input">
 *   <label class="control-label">
 *     My input
 *     <span class="tooltip--anchor">help</span>
 *   </label>
 *   <input type="text" id="modal_my_input" name="modal[my_input]" />
 * </div>
 *
 */

$(document).on("ornament:refresh", function () {
  // Bind your tooltips with <div data-tooltip-anchor="">Show tooltip</div>
  $("[data-tooltip-anchor]").not(".tooltip-initialized").each(function () {

    var $container, anchorText, $anchor, $wrapper, $outer, $inner, $arrow, $content, $text, $close;

    $container = $(this);
    $wrapper = $('<span class="tooltip--wrapper"/>');
    $outer = $('<div class="tooltip--outer"/>');
    $inner = $('<div class="tooltip--inner"/>');
    $arrow = $('<div class="tooltip--arrow"/>');
    $content = $('<div class="tooltip--content"/>');
    $close = $('<div class="tooltip--close">CLOSE</div>');

    // Build anchor.
    anchorText = $container.data("tooltip-anchor");
    $anchor = $("<span class='tooltip--anchor'>" + anchorText + "</span>");

    // Move the anchor in to a control-label if there is a control label in this element
    if ($container.find(".control-label").length > 0) {
      $container.find(".control-label").first().after($anchor);
    } else {
      $container.append("&nbsp;&nbsp;&nbsp;").append($anchor);
    }
    $text = $container.data("tooltip-text");

    // Put all the parts together.
    $anchor.before($wrapper);
    $wrapper.append($anchor);
    $wrapper.append($outer);
    $outer.append($inner);
    $inner.append($arrow);
    $inner.append($content);
    $content.append($close);
    $content.append($text);

    // Hide the content.
    $outer.hide();

    // On click toggle the view state of the tooltip.
    $anchor.on("click", function () {

      if ($outer.is(":visible")) {
        $outer.hide();
      } else {
        $outer.show();
      }

      // check bounds
      var areaAllowance = $(window).width() - $wrapper.offset().left;
      if (areaAllowance < 320) {
        $outer.addClass("tooltip-reposition");
      } else {
        $outer.removeClass("tooltip-reposition");
      }

      return false;
    });

    // Hide on click of close button
    $close.on("click", function () {
      $outer.hide();
      return false;
    });

    // Stop clicking on the tooltip from propogating through to the document
    $outer.on("click onfocus touch", function (event) {
      event.stopPropagation();
    });

    // Hide the box if you click away from the select box
    $(document).on("click", function () {
      $outer.hide();
    });

  }).addClass("tooltip-initialized");

});
