/*jslint browser: true, indent: 2, todo: true, unparam: true */
/*global jQuery,Ornament */

import $ from 'jquery';
import Orn from './defaults';

/**
 *
 * This is a helper to automatically open external links in a new window
 *
 * You can add custom extensions to the Ornament defaults.js file to also force
 * those to open in new windows, eg. ".docx" isn't an external link but may want
 * to be globally opened in a new window.
 *
 * You can add custom dom selectors to the Ornament defaults.js file to skip
 * the behaviour in case an external link needs to be opened in the same window.
 *
 */

var query = [];

// Add suffixes to query.
$.each(Orn.externalLinkExtensions, function (i, v) {
  query.push("[href$='." + v + "']");
  query.push("[href$='." + v.toUpperCase() + "']");
});

// Add prefixes to query.
$.each(["http://", "https://"], function (i, v) {
  query.push("[href^='" + v + "']");
});

// Handle clicks.
$(document).on("click", query.join(", "), function (event) {

  var $link = $(event.target);

  $.each(Orn.internalLinkSelectors, function (i, v) {
    $link = $link.not(v);
  });

  if ($link.length > 0) {
    window.open($link.attr("href"));
    return false;
  }

});
