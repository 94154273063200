// These are scripts specifically for the policies page

import $ from 'jquery';
import moment from 'moment';

$(function () {

  // =========================================================================
  // Autocompletes
  // =========================================================================

  /**
   * Autocomplete bindings for the #policy_rea_code field
   * mcautocomplete docs:
   * https://github.com/charliejw/jQueryUI.MulticolumnAutocomplete
   * jquery ui autocomplete docs:
   * http://jqueryui.com/autocomplete/
   */
  $("#policy_rea_code").mcautocomplete({
    minLength: 2,
    showHeader: true,
    columns: [
      {
        name: 'Name',
        width: '200px',
        valueField: 'name'
      },
      {
        name: 'Suburb',
        width: '150px',
        valueField: 'suburb'
      },
      {
        name: 'State',
        width: '75px',
        valueField: 'state'
      },
    ],
    // source: rea_source,
    source: "/realestate_agents",
    select: function (event, ui) {
      /**
       * Convert the selected data object in to a string and populate the input
       * field with the nicer user-friendly string
       */
      const nice_value = (ui.item.name + ", " + ui.item.suburb + ", " + ui.item.state)
        .replace(/<span class='autocomplete-highlighted'>/g, "")
        .replace(/<\/span>/g, "");
      this.value = (ui.item ? nice_value : '');
      return false;
    },
    response: function (event, ui) {
      if (ui.content.length === 0) {
        // empty element never matches but causes popup to appear
        ui.content.push({label: "", value: ""});
      }
    },
    open: function (event, ui) {
      // When opening, force z-index on parent element
      $(event.target).parent("div").css("z-index", "9999");
    },
    close: function (event, ui) {
      // When closing, reset z-index on parent element
      $(event.target).parent("div").css("z-index", "auto");
    },
    change: function (event, ui) {
      // Get plugin data for 'this'
      const data = $.data(this);
      /**
       * If there is no selected item and the user has typed something in to the
       * field, we want to empty the field so the irrelevant data doesn't get
       * saved in the form
       * This forces the user to make a selection from the autocomplete results
       */
      if (data.customMcautocomplete.selectedItem === undefined) {
        $(this).val("");
      }
    }
  });

  // Autocomplete binding for the #policy_location field
  $("#policy_location").autocomplete({
    source: "/locations/without_po_box",
    extraParams: {type: "Street Address"},
    minLength: 2,
    select: function (event, ui) {
      /**
       * Check the embargo status of the selected item, see the function
       * below for more information
       */
      if (ui.item.value.length > 0) {
        checkEmbargo(ui.item.value);
        checkArrearsLength(ui.item.value);
      }
    },
    change: function (event, ui) {
      //Get plugin data for 'this'
      const data = $.data(this);

      /**
       * If there is no selected item and the user has typed something in to the
       * field, we want to empty the field so the irrelevant data doesn't get
       * saved in the form
       * This forces the user to make a selection from the autocomplete results
       */
      if (data.uiAutocomplete.selectedItem === undefined) {
        $(this).val("");
      }
    }
  });

  // =========================================================================
  // Checking arrears/embargo statuses
  // =========================================================================

  /**
   * checkArrearsLength gets the location value and sends it to the arrears endpoint
   * the endpoint will return a value in the form of an integer, eg 14
   * That value is then used to update the hint message for :tenant_arrears
   */
  function checkArrearsLength(location) {
    $.post({
      url: "/locations/arrears",
      data: "location=" + location,
      headers: { Accept: "application/json; charset=utf-8" }
    })
      .done(function (data) {
        $('#arrears_length').html(data);
      });
  }

  /**
   *
   * checkEmbargo takes a single location as a string
   * location should be formatted as: <SUBURB>, <POSTCODE>, <STATE>
   * eg: ADELAIDE, 5000, SA
   *
   * Send the location string to a backend controller to check the location's
   * embargo status.
   *
   * The cover start date is also required and will be picked up as part
   * of the function.
   *
   * If the location is embargoed, the user cannot continue and it will throw
   * a custom embargo jeopardy state, preventing the user from continuing.
   *
   */
  function checkEmbargo(location) {
    // Find the date on the page
    const $dateField = $('#policy_effective_date');
    const date = $dateField.val();

    /**
     * If the date is present, send the embargo request
     * If the date is not present, fail silently.
     */
    if ($dateField.length > 0) {
      $.post({
        url: "/locations/embargoed",
        data: "location=" + location + "&effective_date=" + date,
        headers: { Accept: "application/json; charset=utf-8" }
      })
        .done(function (result) {
          if (!result.embargo) return;

          // If the embargo is true we need to show a warning and pop up a jeopardy state
          // Open a popup modal
          $.magnificPopup.open({
            type: "ajax",
            items: {
              src: result.jeopardy + "&layout=blank"
            },
            callbacks: {

              /**
               * Once the modal is opened we want to send a custom tracking event
               * These tracking details and dataLayer structures were provided by TSI
               */
              open: function () {
                dataLayer.pageName = "in:ts:secapp:sales:landlord:quote:business_jeopardy";
                dataLayer.pageType = "secapp:sales:landlord:quote:business_jeopardy";
                dataLayer.events = ["JEOPARDY_BUSINESS"];
                webAnalytics.publish("event", "JEOPARDY_BUSINESS");
                dataLayer.jeopardyMessages = "";

                /**
                 * Try the scJeopardy event tracking if available on the page
                 * This is an external function provided by their ensighten tags
                 */
                try {
                  scJeopardy('decline_postcode_under_embargo', 'business');
                } catch (e) {
                }
              }
            }
          });
        });
    }
  }

  // Check embargo and arrears on page load
  const location = $('#policy_location').val();
  if (location !== undefined && location.length > 0) {
    checkEmbargo(location);
    checkArrearsLength(location);
  }

  // =========================================================================
  // Custom date picker
  // =========================================================================

  // Creating our placeholder element for the current selected date
  const effectiveDate = $("#policy_effective_date");
  const effectiveDateText = $(".forms--calendar--text");
  effectiveDate.hide();

  /**
   *
   * jQuery UI Datepicker modification - effective date notice
   *
   * This function gets called over and over to append the effective date notice
   * to the widget if it's visible on the page
   *
   */
  function appendEffectiveDateNote() {
    clearTimeout(appendEffectiveDateNote.timer);
    if ($('#ui-datepicker-div .ui-datepicker-calendar').is(':visible')) {
      $('#ui-datepicker-div').append("<span class='policy_notice'>Your policy start date must be within 30 days of today.</span>");
    } else {
      appendEffectiveDateNote.timer = setTimeout(appendEffectiveDateNote, 10);
    }
    $(".forms--calendar input").datepicker("widget");
  }

  /**
   * A method to convert the jQuery UI datepicker format to a nice
   * human format to display in the date placeholder element
   */
  function toUiDateText(dateText) {
    function formatDay(day) {
      const daySuffixes = ["th", "st", "nd", "rd"];
      const index = (day === 11 || day === 12 || day === 13 ? 0 : day % 10);
      return day + daySuffixes[index > 3 ? 0 : index];
    }

    if (dateText === "") return;

    const date = $.datepicker.parseDate("dd/mm/yy", dateText);
    const day = date.getDate();
    return formatDay(day) + $.datepicker.formatDate(" MM yy", date);
  }

  // Binding the jQuery UI datepicker to our input
  $(".forms--calendar").each(function () {
    const $container = $(this);
    const $input = $container.find("input");
    const $datePlaceholder = $container.find("time");
    const datepickerSettings = {
      buttonText: "Modify Date",
      showOn: "button",
      dateFormat: "dd/mm/yy",
      minDate: 0,
      maxDate: 30,
      dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
      onSelect: function (dateText) {
        /**
         * When selecting a date, get the value and send to our UI displaying
         * the date
         */
        effectiveDate.val(dateText);
        effectiveDateText.text(toUiDateText(dateText));
        effectiveDate.trigger('change');
      },
      onClose: function () {
        /**
         * When closing, call the check embargo again incase a location has been
         * set. Embargo requires a date and a location, so if the user chooses
         * a date, then chooses a location, then goes back to choose a different
         * date it's possible the embargo status is incorrect and needs to be validated
         * again
         */
        $datePlaceholder.text($input.datepicker("getDate"));
        const location = $('#policy_location').val();
        checkEmbargo(location);
      }
    };

    // Set the maximum date on the calendar to be 30 days from today
    const pdsDate = moment();
    const now = moment();
    const isBeforeSwitchover = now.isBefore(pdsDate);
    if (isBeforeSwitchover) {
      datepickerSettings.maxDate = pdsDate.clone().add(30, "day").toDate()
    } else {
      // Add a class to offset the padding required for the effective date notice
      $("body").addClass("forms--calendar__with-note");
    }

    // Bind the datepicker
    $input.datepicker(datepickerSettings);
  });

  // =========================================================================
  // Show the rewired field if the year built was more than 100 years ago
  // =========================================================================

  const $yearBuilt = $("#policy_year_built");
  const $yearRewired = $(".policy_property_rewired");
  const today = new Date();
  const hundredYearsAgo = today.getFullYear() - 100;

  // on select change
  $yearBuilt.on("change", function () {
    const builtValue = $yearBuilt.val();
    // only slide down if is a number and not null
    if (!isNaN(builtValue) && builtValue != null) {
      if (builtValue < hundredYearsAgo) {
        $yearRewired.slideDown(200);
      } else {
        $yearRewired.slideUp(200);
      }
    } else {
      $yearRewired.slideUp(200);
    }
  });

  // on page load (edit view)
  if ($yearBuilt.val() && $yearBuilt.val() < hundredYearsAgo) {
    $yearRewired.show();
  } else {
    $yearRewired.hide();
  }

  // =========================================================================
  // Show/hide bindings
  // implementation of bindings for show.js
  // =========================================================================

  $('.policy_rea_code').attr('data-show-on', 'policy_property_managed_i_have_a_property_or_on-site_manager');
  $('.policy_freestanding').attr({
    'data-show-on': 'policy_cover_option_2_&_policy_cover_option_3',
    'data-show-type': 'any'
  });
  $('.policy_year_built').attr({
    'data-show-on': ['policy_cover_option_2_&_policy_cover_option_3',
    'policy_cover_option_1_&_policy_property_managed_i_manage_the_property_myself'],
    'data-show-type': 'any'
  }); // & policy_cover_option_3
  $('.policy_tenant_arrears').parent("div").attr('data-show-on', 'policy_tenanted_true');
  $('.policy_weekly_rent').attr('data-show-on', 'policy_short_term_rental_false'); // Only for non Short erm rental?
  $('.policy_contents_sum_insured').parent("div").attr('data-show-on', 'is_lsm()'); // Only for LSM policy. Should this display if LSM & RBI?
  $('.policy_furnished').attr('data-show-on', 'is_lsm()');
  $('.policy_security_features').attr('data-show-on', 'policy_furnished_true_&_policy_property_managed_i_manage_the_property_myself');
  $('.policy_building_sum_insured').parent("div").attr({'data-show-on': 'is_rbi()', 'data-show-type': 'any'}); // Only for RBI policy. Should this display if X + RBI?
  $('.policy_mpd_wrapper').attr({
    'data-show-on': 'policy_cover_option_1_&_policy_cover_option_3',
    'data-show-type': 'any'
  });
  $('#mpd_addresses').attr('data-show-on', 'policy_mpd_true');
  $('.policy_cpd_wrapper').attr({
    'data-show-on': 'policy_cover_option_2_&_policy_cover_option_3',
    'data-show-type': 'any'
  });
  $('#cpd_address').attr('data-show-on', 'policy_cpd_true');

  // =========================================================================
  // Security features section
  // =========================================================================

  /**
   * The security features inputs are a unique UI element that is similar to the
   * regular radio button styles but with a checkbox visible inside of it
   */
  const $securityFeaturesRadio = $(".policy_security_features").find("label").last();
  $securityFeaturesRadio.removeClass("checkbox").addClass("radio");
  $securityFeaturesRadio.children("input").removeClass("check_boxes").addClass("radio");

  /**
   * When the user selects none of the above, the other security features should
   * visibly deselect
   */
  const $noneOfAbove = $("#policy_security_features_none_of_the_above");
  const $noneOfAboveOther = $noneOfAbove.parent().siblings().find("input");
  $noneOfAbove.on("change", function () {
    $noneOfAboveOther.prop("checked", false);
    $noneOfAboveOther.parent("label").removeClass("radio-active");
  });
  $noneOfAboveOther.on("change", function () {
    $noneOfAbove.prop("checked", false);
    $noneOfAbove.parent("label").removeClass("radio-active");
  });

});
