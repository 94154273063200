/*jslint browser: true, indent: 2, todo: true, unparam: true */
/*global jQuery,Ornament */

import $ from 'jquery';

/**
 *
 * Re-call all the ornament bindings and initialisation whenever the
 * ornament:refresh event is called.
 *
 * $(document).trigger("ornament:refresh");
 *
 * This by default happens in two places:
 *
 * 1. On page load
 * 2. Whenever pjax:end gets called (if using pjax)
 *
 * It may be beneficial to call this function when AJAXing in content as the new
 * nodes won't have their javascript bindings.
 *
 */

$(function () {
  $(document).trigger("ornament:refresh");
});

$(document).on("pjax:end", "*", function () {
  $(document).trigger("ornament:refresh");
});
