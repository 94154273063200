/**
 * 
 * This is a helper to show/hide elements when a form field changes value
 * 
 * Assume you have a field with the ID of "my-field-1", eg:
 * <input type="checkbox" id="my-field-1" />
 * 
 * You can add the data-show-on attribute to an element to show the element
 * when that checkbox is ticked, and hide it when that checkbox is unticked
 * <div data-show-on="my-field-1">
 * 
 * If the element needs to show if there are multiple inputs checked, you can
 * separate your show on rules with the _&_ seperator
 * <div data-show-on="my-field-1_&_my-field-2">
 * 
 * If the element needs to show if ANY of a series of inputs are changed, you
 * can add the data-show-type rule:
 * <div data-show-on="my-field-1_&_my-field-2" data-show-type="any">
 * 
 * You can reverse the logic by adding the data-show-type attribute:
 * <div data-show-on="my-field-1" data-show-type="not">
 * 
 */

import $ from 'jquery';

$(function(){

  // function to determine wether something should be visible or not
  var showHide = function($target,$control,$allShowControls,$this){
    /**
     * Check if the control is a (fake) <select> element by checking
     * for the class
     */
    var isSelect = false;
    if($control.hasClass("select--option")) {
      isSelect = true;
    }

    // Check which show-type we're working with
    var showType = $target.data("show-type");

    // Reset showObject to false
    var showObject = false;

    // Matching for "any" show-type case
    if( showType === "any" ) {

      // itrate through each show-on control
      $($allShowControls).each(function(){
       
        /**
         * Only keep going if showObject is still false
         * Once any of the controls have matched below, the showObject
         * variable will be set to true so we don't need to keep checking as
         * we only care that ANY of the fields have matched
         */
        if( showObject !== true ) {

          /**
           * If this control is a select option, we need to check if
           * this option has the active class to determine if it is selected
           */
          if(isSelect) {
            if( $control.hasClass("select--option-active") ) {
              showObject = true;
            } else {
              showObject = false;
            }

          /**
           * If it's not a select, we assume it's a checkbox and check the "checked"
           * prop to determine if it's selected
           */
          } else {
            if( $(this).prop("checked") ) {
              showObject = true;
            } else {
              showObject = false;
            }
          }
        }
      });

    // If it's the "not" type
    } else if (showType === "not") {
      // Assume we're going to show the element by default
      showObject = true;

      // Itrate through each show-on control
      $($allShowControls).each(function(){
        if( showObject !== false ) {

          /**
           * If this control is a select option, we need to check if
           * this option has the active class to determine if it is selected
           */
          if(isSelect) {
            if( $control.hasClass("select--option-active") ) {
              showObject = false;
            } else {
              showObject = true;
            }

          /**
           * If it's not a select, we assume it's a checkbox and check the "checked"
           * prop to determine if it's selected
           */
          } else {
            if( $(this).prop("checked") ) {
              showObject = false;
            } else {
              showObject = true;
            }
          }
        }
      });

    // Matching assumed "all" case (default)
    } else {
      // Assume we're showing the element by default
      showObject = true;

      /**
       * Loop over each control and if ANY of them match, set showing state
       * to false
       */
      $($allShowControls).each(function(){

        /**
         * If this control is a select option, we need to check if
         * this option has the active class to determine if it is selected
         */
        if(isSelect) {
          if($control.hasClass("select--option-active") != true) {
            showObject = false;
          }

        /**
         * If it's not a select, we assume it's a checkbox and check the "checked"
         * prop to determine if it's selected
         */
        } else {
          if($(this).prop("checked") != true){
            showObject = false;
          }
        }
      });

    }

    // show/hide based on showObject variable after the loop
    if( showObject ) {
      $this.slideDown(200);
    } else {
      $this.slideUp(200);
    }
  };

  // loop through elements with data-show-on attributes and bind the functionality
  $("[data-show-on]").each(function(){
    var $this, showOn, $showOnControls, $allControls, controlSeperator, showObject, showOnLoad, showType;

    $this = $(this);
    showOn = $this.data("show-on");
    controlSeperator = "_&_";
    showObject = false;
    showOnLoad = false;
    showType = $this.data("show-type");

    /**
     * Check for functions being passed
     * eg. data-show-on="is_sss()"
     * functions are being handled in product_type.js
     */
    if(showOn.indexOf("()") > -1) {
      return;
    } else {

      // Account for multiple options to show a single element
      if( showOn.indexOf(controlSeperator) > -1 ) {
        $showOnControls = showOn.split(controlSeperator);
      } else {
        $showOnControls = [showOn];
      }

      // Create an array of all showOn controls
      var $allShowControls = [];
      $.each($showOnControls, function(){
        $allShowControls.push( $("#" + this ) );
      });

      // Iterate through each showOn
      $.each($allShowControls, function(){

        var $control = $(this);
        var isSelect = false;
        if($control.hasClass("select--option")) {
          isSelect = true;
        }

        // Check if ID matches a select element
        if(isSelect) {
          // allControls defaults back to original select element
          $allControls = $control.closest(".select-pretty").prev("select");
        } else {
          // Sibling options
          $allControls = $control.parent().parent().find("input");
        }

        // On change
        $allControls.on("change",function(){
          showHide($this,$control,$allShowControls,$this);
        });

        // show hide on page load
        showHide($this,$control,$allShowControls,$this,true);
      
      });

    }

  });

});
