/**
 * These are a bunch of custom form field behaviours.
 * These range from custom radio buttons and checkboxes to custom maxlength and
 * things like min/max number ranges
 */

import $ from 'jquery';

$(function(){

  /**
   * Radio buttons
   *
   * Clicking on the label for a radio button should change the class on the label
   * and change the checked prop on the radio button to create custom styled
   * radio buttons
   */
  var $radioLabels = $("label.radio input");
  var radioActiveClass = "radio-active";
  $radioLabels.on("click", function(e){
    var $input = $(this);
    var $label = $(this).parent("label");
    $label.addClass(radioActiveClass).siblings("label.radio").removeClass(radioActiveClass);
  });

  /**
   * Checkboxes
   *
   * Work in a similar way to the radio buttons above.
   * When clicking on the input, change the class on the label
   */
  var $checkboxes = $("label.checkbox input");
  var $checkboxLabels = $("label.checkbox");
  var checkboxActiveClass = "radio-active";
  $checkboxes.on("change", function(){
    var $input = $(this);
    var $label = $(this).parent("label");
    if ($input.prop("checked") == true) {
      $label.addClass(checkboxActiveClass);
    } else {
      $label.removeClass(checkboxActiveClass);
    }
  });

  /**
   * Yes/No buttons
   *
   * Wrap each yes/no in a div so we can style them as yes/no options and separate
   * them from the sibling label to fix spacing issues from the vertial rhythm styles
   */
  $(".radio_yes_no").each(function(){
    var $this = $(this);
    var $radios = $this.children(".radio");
    var $wrapper = $("<div />");
    $radios.appendTo($wrapper);
    $wrapper.appendTo($this);
  });

  /**
   * Default states for checkboxes and radio buttons
   *
   * Updating the classes and styles for default checked radios and checkboxes
   */
  $.fn.updateRadioButtons = function(){
    $("label.radio").find("input:checked").change().parent("label.radio").addClass(radioActiveClass).siblings("label.radio").removeClass(radioActiveClass);
    $("label.checkbox").find("input:checked").change().parent("label.checkbox").addClass(radioActiveClass);
  }
  $.fn.updateRadioButtons();

  /**
   * Select boxes
   *
   * Building a fake select box to replace all select boxes (with a class of "select") on
   * the page with a custom-styled select box
   */
  var $selectBoxes = $("select.select");
  $selectBoxes.not(".select-init").each(function(){
    var $select = $(this);
    var $options = $select.children("option");
    var defaultText = "";
    var selectId = $select.attr("id");

    // Is there an option selected already?
    if ( $select.val() != "") {
      defaultText = $select.val();
      // Get label text from option rather than value
      var $option = $select.find("option[value=\"" + defaultText + "\"]");
      if($option) {
        defaultText = $option.text();
        $option.addClass("select-active");
      }
    } else {
      // If not, use the first option
      defaultText = $options.first().text();
    }

    /**
     * Create an anchor div
     * This is the default state of the select menu, showing the selected/default
     * value, clicking on it will show/hide the options
     */
    var $selectAnchor = $("<div class='select--anchor'>"+defaultText+"</div>");

    // Duplicate the options in to a list element
    var $optionsDiv = $("<ul class='select--options'></ul>");
    $options.each(function(){
      var $originalOption = $(this);
      var optionText = $originalOption.text();
      var optionValue = $originalOption.attr("value");
      var optionId = selectId + "__" + optionText.toLowerCase();
      var $optionDiv = $("<li class='select--option' id='"+optionId+"' data-select-val='" + optionValue + "'>"+ optionText +"</li>");
      if ( $select.val() === optionValue) {
        $optionDiv.addClass("select--option-active");
      }
      // Clicking on an option sets as active text
      $optionDiv.on("click", function(){
        $selectAnchor.text(optionText);
        $(this).addClass("select--option-active").siblings("li").removeClass("select--option-active");
        $select.val(optionValue).change();
      });
      $optionDiv.appendTo($optionsDiv);
    });

    // Hide optionsDiv by default
    $optionsDiv.hide();

    // Clicking on the anchor will show the options div
    $selectAnchor.on("click", function(){
      $selectAnchor.toggleClass("select-active");

      // Close other optionsDivs
      $(".select--options").not($optionsDiv).hide().prev(".select--anchor").removeClass("select-active");

      // If the options div is already visible, hide the menu as it acts like a toggle
      if( $optionsDiv.is(":visible") ) {
        $optionsDiv.hide();
        $optionsDiv.parent().css("z-index","1");
      } else {
        $optionsDiv.show();
        $optionsDiv.parent().css("z-index","999");
      }
      return false;
    });

    // Add our fake select elements after our select boxes
    var $newSelect = $("<div class='select-pretty "+selectId+"' id='"+selectId+"_pretty'></div>");
    $newSelect.append($selectAnchor).append($optionsDiv);
    $select.hide().after($newSelect);

    // Clicking away from the select menu should hide the options dropdown
    $(document).on("click", function(){
      $optionsDiv.hide();
      $selectAnchor.removeClass("select-active");
    });
  }).addClass("select-init");

  /**
   * Date fields
   *
   * Custom behaviours for date fields that are made up of seperate day/month/year fields
   * 1. Empty the field if the value is not a number
   * 2. Add an initial zero if they enter a single digit, eg. 7 = 07
   * 3. Showing custom errors for each individual field if the number entered is
   *    larger than the maximum (eg. months shouldn't be higher than 12)
   */
  var $dateFields = $(".forms--date");
  $dateFields.each(function(){
    var $date = $(this);
    var $dayField = $date.find("[data-date-type=day]");
    var $monthField = $date.find("[data-date-type=month]");
    var $yearField = $date.find("[data-date-type=year]");
    var $dayError = $("<div class='error error_day'>Day cannot exceed 31.</div>");
    var $monthError = $("<div class='error error_month'>Month cannot exceed 12.</div>");
    // var $yearError = $("<div class='error error_year'></div>");

    $dayField.on("change", function(){
      var $thisDayField = $(this);

      /**
       * Remove the field value (and error) if the value the user enters isn't
       * a number
       */
      if( isNaN(parseInt($thisDayField.val())) ) {
        $thisDayField.val("");
        $dayError.remove();
        return false;
      }

      // Add preceeding zero if value is less than 10
      if( parseInt($thisDayField.val()) < 10 && $thisDayField.val().indexOf(0) < 0 ) {
        $thisDayField.val( "0" + $thisDayField.val() );
        $dayError.remove();
      } else {
        // Add errors if needed
        if ( parseInt($thisDayField.val()) > 31 ) {
          $date.append($dayError);
        } else {
          $dayError.remove();
        }
      }
    });

    $monthField.on("change", function(){
      var $thisMonthField = $(this);

      /**
       * Remove the field value (and error) if the value the user enters isn't
       * a number
       */
      if( isNaN(parseInt($thisMonthField.val())) ) {
        $thisMonthField.val("");
        $monthError.remove();
        return false;
      }

      // Add preceeding zero if value is less than 10
      if( parseInt($thisMonthField.val()) < 10 && $thisMonthField.val().indexOf(0) < 0 ) {
        $thisMonthField.val( "0" + $thisMonthField.val() );
        $monthError.remove();
      } else {
        // Add errors if needed
        if ( parseInt($thisMonthField.val()) >= 13 ) {
          $date.append($monthError);
        } else {
          $monthError.remove();
        }
      }
    });


  });

  /**
   * Dollar fields
   *
   * An implementation of the priceFormat jQuery plugin to automatically format
   * the input field as a dollar value
   * Adds a custom dollar label at the beginning of the dollar fields
   */
  var $dollarFields = $(".form-with-dollar");
  $dollarFields.not(".form-with-dollar-init").each(function(){
    var $input = $(this).find("input");
    var inputId = $input.attr("id");
    var $extraLabel = $("<label for='"+inputId+"' class='form-with-dollar--label'>$</label>");
    $input.before($extraLabel);
    // trigger price format plugin
    $input.priceFormat({
      prefix: '',
      centsLimit: 0
    });
  }).addClass("form-with-dollar-init");

  /**
   * Lightbox anchors from data attributes
   *
   * This is a way to force a lightbox link inside a form field label using javascript
   * <span data-lightbox-for-label><a href="#blah">Show #blah</a></span>
   * <%= f.input ...>
   *
   * This will take that span and inject it in to the label element in the f.input helper
   */
  $("[data-lightbox-for-label]").each(function(){
    const $lightboxLink = $(this);
    const $fieldLabel = $lightboxLink.next("div").find("label").first();
    $lightboxLink.appendTo($fieldLabel);
    $lightboxLink.before("<br />");
  });

  /**
   * Maxlength validation
   *
   * This is a way to define a maxlength on an input field and show an error
   * if the value of the input exceeds that maxlength
   *
   * <input data-maxlength="200" />
   */
  $("input[data-maxlength]").each(function(){
    var maxlength, $input, $maxError, $wrapper;

    $input = $(this);
    maxlength = $input.data("maxlength");
    $input.attr("maxlength",maxlength);

    $maxError = "<p class='error error-inline'>Maximum "+maxlength+" characters.</p>";
    $wrapper = $input.parent("div");

    $input.on("keyup", function(){
      var currentlength = $(this).val().length;
      if( $input.data("no-validation") == "" ) {
        return false;
      }
      // remove errors
      $wrapper.find(".error-inline").remove();
      // add errors if needed
      if(currentlength > maxlength-1) {
        $wrapper.append($maxError);
      }
    });

  });

  /**
   * Programatic Tabindexing
   * Take all these elements and force an incrementing tabIndex
   */
  var tabIndex = 0;
  var $selectables = $("label.radio, label.checkbox, .select--anchor, input, button");
  $selectables.each(function(){
    tabIndex++;
    $(this).attr("tabindex", tabIndex);
  });

  /**
   * A helper to force clicking on the input the user is focused on whenever pressing
   * <enter> on the keyboard
   */
  $('body').on('keydown', function(event) {
    if (event.keyCode === 13) {
      var $focusedInput = $(":focus");
      $focusedInput.click();
    }
  });
});
