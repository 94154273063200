/**
 *
 * Instant Jeopardy modals
 *
 * A "Jeopardy" case is a case where the user inputs can't continue with their
 * submission. These are cases where the client will not be able to support them
 * with an online submission and will usually either prompt the user to continue
 * with a different method of inquiry or highlight issues with their submission.
 */

import $ from 'jquery';

function jeopardyUrl(type, params = {}) {
  const base = `/policy_details/jeopardy/?jeopardy[type]=${type}`;
  return Object.entries(params).reduce((url, [k, v]) => url + `&jeopardy[${k}]=${v}`, base)
    + "&layout=blank";
}

/**
 * Fire the jeopardy modal – loads a page from the server and shows it to the user.
 */
function showJeopardy(jeopardyUrl, trackingCode, onClose = null) {
  // magnific popup modal
  $.magnificPopup.open({
    type: "ajax",
    closeOnContentClick: false,
    items: { src: jeopardyUrl },
    callbacks: {
      /**
       * Once the ajax content has loaded we can send any required
       * tracking events
       */
      ajaxContentAdded: function () {
        trackJeopardyEvent(this.content.find("h2").text(), trackingCode)
      },
      close: onClose
    }
  });
}

function closeJeopardy($link) {
  if ($link.closest(".mfp-content").length > 0) {
    $.magnificPopup.close();
  } else {
    history.back();
  }
}

/**
 * TSI cannot issue new policies at this time
 */
function registerDeclineNewBusinessJeopardy() {
  if ($("#new_business_jeopardy").val() === 'true') {
    showJeopardy(jeopardyUrl("decline_new_business"), "decline_new_business_embargoed", () => {
      window.location.href = "https://www.terrischeer.com.au";
    });
  }
}

/**
 * Disqualifying criteria.
 */
function registerDisclosureJeopardy() {
  const $eligibility = $("#policy_eligibility_criteria_true");

  $eligibility.change(function () {
    // radio button/checkbox types
    if ($eligibility.prop("checked")) {
      if (is_rbi()){
        showJeopardy(jeopardyUrl("building_disclosure"), "decline_eligibility_criteria_not_met", () => {
          clearCheckbox($eligibility);
        });
      } else{
        showJeopardy(jeopardyUrl("content_disclosure"), "decline_eligibility_criteria_not_met", () => {
          clearCheckbox($eligibility);
        });
      }
    }
  });
}

/**
 * TSI does not issue LSM policies if the tenant is already in arrears.
 */
function registerTenantArrearsJeopardy() {
  const $triggers = $("#policy_tenanted_true, #policy_tenant_arrears_true");

  // every time any of the triggers are changed
  $triggers.change(function () {
    const $location = $("#policy_location");
    const state = $location.val().split(", ")[2];
    if (is_lsm() && Array.from($triggers).every($e => $e.checked)) {
      showJeopardy(jeopardyUrl("tenant_arrears", { state: state }), "decline_Tenant behind in rental payments", () => {
        clearCheckbox($(this));
      });
    }
  })
}

/**
 * TSI does not policies for properties larger than 5 acres
 */
function registerLandSizeJeopardy() {
  const $land_size = $("#policy_land_size_2");

  // every time any of the triggers are changed
  $land_size.change(function () {
    if ($land_size.prop("checked")) {
      showJeopardy(jeopardyUrl("land_size"), "decline_property_larger_than_5_acres", () => {
        clearCheckbox($land_size);
      });
    }
  })
}

/**
 * TSI requires over the phone confirmation for land area 2-5 acres
 */
function registerLandSizeTwoToFiveJeopardy() {
  const $land_size = $("#policy_land_size_1");
  $land_size.change(function () {
    if ($land_size.prop("checked")) {
      showJeopardy(jeopardyUrl("land_size_two_to_five"), "decline_property_between_2_to_5_acres", () => {
        clearCheckbox($(this));
      })
    }
  });
}

/**
 * TSI does not issue policies to buildings
 */
function registerRewiringJeopardy() {
  const $triggers = $("#policy_year_built, #policy_property_rewired_false");
  const $year_built = $("#policy_year_built");
  const $not_rewired = $("#policy_property_rewired_false");
  const threshold = new Date().getFullYear() - 100;

  function isOld() {
    const builtValue = $year_built.val();
    return builtValue && parseInt(builtValue) <= threshold;
  }

  // every time any of the triggers are changed
  $triggers.change(function () {
    if (isOld() && $not_rewired.prop("checked")) {
      showJeopardy(jeopardyUrl("rewiring"), "decline_property_not_rewired_&_replumbed", () => {
        clearCheckbox($not_rewired);
      });
    }
  })
}

/**
 * TSI cannot issue policies to non-freestanding properties online.
 */
function registerFreestandingJeopardy() {
  const $not_freestanding = $("#policy_freestanding_false");

  // every time any of the triggers are changed
  $not_freestanding.change(function () {
    if ($not_freestanding.prop("checked")) {
      showJeopardy(jeopardyUrl("freestanding"), "direct_to_call_property_not_freestanding", () => {
        clearCheckbox($not_freestanding);
      });
    }
  })
}

/**
 * RBI building insurance policies are not available in Western Australia above
 * the 26th parallel (6700..6999).
 */
function registerPermanentEmbargoJeopardy() {
  const $location = $("#policy_location");

  function insurable() {
    return !$location.val().match(/6[7-9]\d{2}/);
  }

  $location.on("autocompletechange", function () {
    if (is_rbi() && !insurable()) {
      showJeopardy(jeopardyUrl("permanent_embargo"), "decline_property_north_of_26th_parallel", () => {
        $location.val("");
      });
    }
  })
}

/**
 * Due to COVID TSI is not currently offering insurance for short stay.
 */
function registerShortStayEmbargoJeopardy() {
  if ($("#short_stay_jeopardy").val() !== 'true') return;

  const $short_term = $("#policy_short_term_rental_true");
  $short_term.change(function () {
    if ($short_term.prop("checked")) {
      showJeopardy(jeopardyUrl("short_stay_embargo"), "decline_short_term_rental", () => {
        clearCheckbox($short_term);
      });
    }
  });
}

/**
 * Ensures that input value is between min and max
 *
 * @returns {boolean}
 */
function checkDollarRange($input, min, max) {
  const value = $input.val().replace(/,/g, "")

  if (value === "") return true;
  if (value === "0") {
    $input.val("");
    return true;
  }

  return value >= min && value <= max;
}

/**
 * TSI needs more information about the type of business activity for properties with business activities
 */
function registerBusinessActivityJeopardy() {
  const $triggers = $("#policy_business_or_trade_true");
  $triggers.change(function () {
    if (Array.from($triggers).every($e => $e.checked)) {
      showJeopardy(jeopardyUrl("business_or_trade"), "decline_business_or_trade", () => {
        clearCheckbox($(this));
      })
    }
  });
}

/**
 * Contents sum insured should be between $30,000 and $100,000
 */
function registerContentsSumInsuredJeopardy() {
  const $contents_sum_insured = $("#policy_contents_sum_insured, #quote_contents_sum_insured");

  $contents_sum_insured.blur(function () {
    if (!checkDollarRange($contents_sum_insured, 30000, 100000)) {
      showJeopardy(jeopardyUrl("contents_sum_insured"), "contents_insured_amount", () => {
        $contents_sum_insured.val("");
      });
    }
  });
}

/**
 * Building sum insured should be between $100,000 and $2,000,000
 */
function registerBuildingSumInsuredJeopardy() {
  const $building_sum_insured = $("#policy_building_sum_insured, #quote_building_sum_insured");

  $building_sum_insured.blur(function () {
    if (!checkDollarRange($building_sum_insured, 100000, 2000000)) {
      showJeopardy(jeopardyUrl("building_sum_insured"), "building_insured_amount", () => {
        $building_sum_insured.val("");
      });
    }
  });
}

/**
 * Weekly rent should be between $150 and $3,000
 */
function registerWeeklyRentJeopardy() {
  const $weekly_rent = $("#policy_weekly_rent");

  $weekly_rent.blur(function () {
    if (!checkDollarRange($weekly_rent, 150, 3000)) {
      showJeopardy(jeopardyUrl("weekly_rent"), "weekly_rent_amount", () => {
        $weekly_rent.val("");
      });
    }
  });
}

/**
 * Clear a checkbox or radio button
 */
function clearCheckbox($this) {
  $this.prop("checked", false).parent("label").removeClass("radio-active").removeClass("checkbox-active");
  $this.change();
}

/**
 * Notify TSI analytics that a jeopardy event has occurred (called from popup)
 */
function trackJeopardyEvent(errorMessage, trackingCode) {
  if (errorMessage) {
    dataLayer.jeopardyMessages = errorMessage;
  }
  dataLayer.pageName = "in:ts:secapp:sales:landlord:quote:business_jeopardy";
  dataLayer.pageType = "secapp:sales:landlord:quote:business_jeopardy";
  dataLayer.events = ["JEOPARDY_BUSINESS"];
  webAnalytics.publish("event", "JEOPARDY_BUSINESS");
  /**
   * scJeopardy is only present in production so we'll wrap this
   * code around a try/catch block to fail silently
   */
  try {
    scJeopardy(trackingCode, 'business');
  } catch (e) {
  }
}

$(function () {
  registerDeclineNewBusinessJeopardy();
  registerDisclosureJeopardy();
  registerTenantArrearsJeopardy();
  registerLandSizeJeopardy();
  registerLandSizeTwoToFiveJeopardy();
  registerRewiringJeopardy();
  registerBusinessActivityJeopardy();
  registerFreestandingJeopardy();
  registerPermanentEmbargoJeopardy();
  registerShortStayEmbargoJeopardy();
  registerContentsSumInsuredJeopardy();
  registerBuildingSumInsuredJeopardy();
  registerWeeklyRentJeopardy();

  $(document).on("click", 'a[href="#close-jeopardy"]', (e) => {
    e.preventDefault();
    closeJeopardy($(e.target));
  });
});
